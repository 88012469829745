var render = function render(){var _vm=this,_c=_vm._self._c;return _c('bm-overlay',{ref:"customOverlay",staticClass:"sample",attrs:{"pane":"labelPane"},on:{"draw":_vm.draw}},[_c('div',{attrs:{"title":_vm.item.v_elevator_name},on:{"click":_vm.onTap}},[(_vm.item.status == 1)?_c('div',{staticClass:"sample normal"}):_vm._e(),(_vm.item.status == 3)?_c('div',{staticClass:"sample repair"}):_vm._e(),(_vm.item.status == 4)?_c('div',{staticClass:"sample offline",class:_vm.item.fault &&
      _vm.item.showFault &&
      !_vm.closeFaultIds.includes(_vm.item.v_fault_id)
        ? 'createAnimation'
        : ''}):_vm._e(),(_vm.item.status == 5)?_c('div',{staticClass:"sample trap",class:_vm.item.fault &&
      _vm.item.showFault &&
      !_vm.closeFaultIds.includes(_vm.item.v_fault_id)
        ? 'createAnimation'
        : ''}):_vm._e(),(_vm.item.status == 2)?_c('div',{staticClass:"sample fault",class:_vm.item.fault &&
      _vm.item.showFault &&
      !_vm.closeFaultIds.includes(_vm.item.v_fault_id)
        ? 'createAnimation'
        : ''}):_vm._e(),(
      _vm.item.fault &&
        _vm.item.showFault &&
        !_vm.closeFaultIds.includes(_vm.item.v_fault_id)
    )?_c('div',{staticClass:"placeholder"},[_c('div',{staticClass:"popover-content box-shadow"},[_c('div',{staticClass:"popover-header"},[_c('img',{staticStyle:{"width":"20px","height":"20px","position":"absolute","right":"0","top":"50%","transform":"translate(0,-50%)"},attrs:{"src":require("@/assets/ic_pop_close.png")},on:{"click":function($event){$event.stopPropagation();return _vm.onClose.apply(null, arguments)}}}),_c('div',[_vm._v("故障信息")])]),_c('div',{staticClass:"fault-tips-text"},[_c('div',[_vm._v("小区名称：")]),_c('div',[_vm._v(_vm._s(_vm.item.fault.villageName))])]),_c('div',{staticClass:"fault-tips-text"},[_c('div',[_vm._v("电梯名称：")]),_c('div',[_vm._v(_vm._s(_vm.item.fault.v_elevator_name))])]),_c('div',{staticClass:"fault-tips-text"},[_c('div',[_vm._v("故障名称：")]),_c('div',[_vm._v(_vm._s(_vm.item.fault.v_fault_name))])])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }