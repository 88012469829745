<template>
    <div style="width: 100%; height: 100%;">
        <baidu-map v-if="show" class="map" :center="center" :zoom="zoom" @ready="handler" :scroll-wheel-zoom="true" :map-click="false"  :pinch-to-zoom="true" :double-click-zoom="true" map-type="BMAP_NORMAL_MAP">
            <my-overlay v-for="(item, index) in dataList" :key="index" :item="item" :position="{ lng: item.v_longitude, lat: item.v_latitude }" :active="item.active" @mouseover.native="item.active = true" :index="index" @mouseleave.native="item.active = false" @openMapDrawer="openMapDrawer(item)"></my-overlay>
        </baidu-map>
    </div>
</template>
<script>
import MyOverlay from './MyOverlay.vue'
import * as ElevatorApi from "@/api/elevator/index";
import { debounce } from '@/common/utils/index'
export default {
    name: 'TestBaiDu',
    components: {
        MyOverlay
    },
    data() {
        return {
            center: { lng: 118.744398, lat: 31.938133 },
            zoom: 12,
            mapDrawer: false,
            dataList: [],
            mapDrawerTitle: '',
            villageElevatorList: [],
            elevatorPosition: {},
            BMap: '',
            map: '',
            bmInfoWindow: false,
            v_village_id: '',
            // 监控
            MONITORModalFlag: false,
            elevatorCode: '',
            elevatorId: '',
            monitor_flag: true,
            show: false,
            showZoomControl: false,

            cursor: 0,
            eventHandler: null,
            //可以被显示在视图上的故障
            visibleEvents: [],
            locatePoint: null,

        }
    },
    created() {},
    mounted() {
        this.getElevatorList()
        this.eventHandler = debounce(() => {
            if (this.visibleEvents.length) {
                this.cursor = ++this.cursor % this.visibleEvents.length
                this.updateByEvents(this.visibleEvents)
            }
        }, 10000, false)
    },
    computed: {
        theme() {
            return this.$store.getters.theme
        },
        user() {
            return this.$store.state.user
        },
        eventList() {
            return this.$store.state.event.eventList
        },
        closeFaultIds() {
            return this.$store.state.event.closeFaultIds
        }
    },
    methods: {

        async updateElevatorStatus() {
            const res = await ElevatorApi.getElevatorsStatus({
                projectId: this.buildParams({}).vProjectId
            })
            let elevatorList = res.info
            if (elevatorList.length) {
                this.dataList.forEach((elevator) => {
                    let find = elevatorList.find((item) => {
                        return item.v_elevator_id == elevator.v_elevator_id
                    })
                    if (find) {
                        if (find.i_on_line == 0) {
                            elevator.status = 4
                        } else if (find.i_fault_status == 2) {
                            elevator.status = 5
                        } else if (find.i_fault_status == 1) {
                            elevator.status = 2
                        } else if (find.i_mode_status != 0) {
                            elevator.status = 3
                        } else {
                            elevator.status = 1
                        }
                    }
                })
                this.dataList = JSON.parse(JSON.stringify(this.dataList))
            }
        },

        handler({ BMap, map }) {
            this.BMap = BMap
            this.map = map
            this.map.setMapStyleV2({
                styleId: '2f143edcc9ba35e22eec789b33d8eecc'
                // styleId: '78d5ea7fd9c6009e5ae925917bed0b26'
            })
            if (this.locatePoint)
                this.map.centerAndZoom(this.locatePoint, 20)
            else 
                this.locateIfNeeded()
        },
        updateByEventList(eventList) {
            let visibleEvents = []
            this.dataList.forEach((item) => {
                let items = eventList ? eventList : []
                item.animate = items.find((val) => {
                    if (val['v_elevator_code'] == item.v_elevator_code) {
                        visibleEvents.push(val)
                        return true
                    } else {
                        return false
                    }
                }) ? true : false
            })
            this.visibleEvents = visibleEvents
            this.updateByEvents(this.visibleEvents)
        },
        updateByEvents(events) {
            events = events.filter((event) => {
                return !this.closeFaultIds.includes(event.v_fault_id)
            })
            if (events.length) {
                if (events.length > 1) {
                    this.handleEvent(events[this.cursor])
                    this.eventHandler()
                } else {
                    this.handleEvent(events[0])
                }
            } else {
                this.dataList.forEach((item) => {
                    delete item.fault
                    item.showFault = false
                })
                this.dataList = JSON.parse(JSON.stringify(this.dataList))
            }
        },
        handleEvent(event) {
            this.dataList.forEach((item) => {
                delete item.fault
                item.showFault = false
            })
            let find = this.dataList.find((item) => {
                return event['v_elevator_code'] == item.v_elevator_code
            })
            if (find) {
                find.fault = event
                find.showFault = !this.closeFaultIds.includes(find.v_fault_id)
                const point = new BMap.Point(find.v_longitude, find.v_latitude)
                this.locatePoint = point
                if (this.map)
                    this.map.centerAndZoom(point, 19)
                this.dataList = JSON.parse(JSON.stringify(this.dataList))
            }
        },
        getElevatorList() {
            ElevatorApi.searchElevatorNotPage({}).then(async res => {
                if (res.code === '0000') {
                    this.show = true
                    res.info.forEach((item) => {
                        item['active'] = false
                        item['showFault'] = false
                        item['fault'] = null
                    })
                    this.dataList = res.info.slice(0, 40)
                    this.locateIfNeeded()

                    this.updateElevatorStatus()
                    // this.dataList = this.parseElevator(res.info)
                    //TODO 电梯数据懒加载 临时方案
                    this.updateByEventList(this.eventList)
                }
            })
        },
        locateIfNeeded() {
            if (this.map) {
                let point = new BMap.Point(121.704899, 31.397188)
                if (this.dataList.length) {
                    let array = this.dataList
                    if (array.length)
                        point = new BMap.Point(array[0].v_longitude, array[0].v_latitude)
                }
                this.map.centerAndZoom(point, 19)
                // this.showZoomControl = true
                var navigationControl = new BMap.NavigationControl({
                    // 靠左上角位置
                    anchor: BMAP_ANCHOR_BOTTOM_RIGHT,
                    // LARGE类型
                    type: BMAP_NAVIGATION_CONTROL_SMALL,
                    // 启用显示定位
                    //enableGeolocation: true
                });
                this.map.addControl(navigationControl);
            }
        },
        openMapDrawer(data) {
            console.log(data, '3454444');
            this.$emit('openMapDrawer2', data)
        },

        onEnlarge() {

        },

        onNarrow() {

        },
        buildParams(params) {
            params.vProjectId = this.user.user.v_project_id
            return params
        },

    },
    watch: {
        theme(newVal, oldVal) {
            if (newVal != oldVal) {
                if (this.map) {
                    if (newVal == 'dark')
                        this.map.setMapStyleV2({
                            styleId: '2f143edcc9ba35e22eec789b33d8eecc'
                        })
                    else
                        this.map.setMapStyleV2({
                            styleId: 'e3aff207b5f958bcd4da897ea4230104'
                        })
                }

            }
        },
        "eventList": {
            handler(val) {
                this.updateByEventList(val)
            }
        }
    }
}
</script>
<style lang="less" scoped>
.map {
    width: 100%;
    height: 100%;
    position: relative;
}

::v-deep .BMap_stdMpZoom {
    height: 70px !important;
}

::v-deep .BMap_stdMpZoomOut {
    top: 30px !important
}


.footer_info {
    height: 100%;
    overflow: auto;
}

.footer_info::-webkit-scrollbar {
    display: none;
}

.control {
    position: absolute;
    bottom: 70px;
    right: 30px;
    width: 44px;
    background: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .08);
    border-radius: 4px;
}

.control-icon {
    width: 12px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    cursor: pointer;
}

.control-btn {
    width: 44px;
    height: 28px;
    display: block;
    cursor: pointer;
    text-align: center;
    margin: 16px 0
}
</style>