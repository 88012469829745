<template>
    <div class="vallage-list">
        <div class="nav">
            <div class="state">
                <div v-for="item in modeList" @click="onChangeState(item)" :class="stateActive == item.key ? 'active type' : 'type'">
                    {{item.label}}
                </div>
            </div>
        </div>
        <div style="height:100%;" v-for="item in modeList" v-if="item.key == stateActive">
            <div v-if="item.key.indexOf('device')> -1 && deviceList.length " class="elevator-content">
                <div class="ele-state">
                    <div class="item">
                        <p style="background: #22c55e"></p>
                        <div class="label">正常</div>
                    </div>
                    <div class="item">
                        <p style="background: #f69"></p>
                        <div class="label">离线</div>
                    </div>
                    <div class="item">
                        <p style="background: red"></p>
                        <div class="label">困人</div>
                    </div>
                    <div class="item">
                        <p style="background: #00FFFF"></p>
                        <div class="label">检修</div>
                    </div>
                    <div class="item">
                        <p style="background: orange"></p>
                        <div class="label">故障</div>
                    </div>
                </div>
                <div class="elevator-list ">
                    <div class="item-box grid-container">
                        <!-- <div class="title">2号楼</div> -->
                        <div class="item" v-for="(item, index) in deviceList" :key="index" @click="onGoMonitor(item)">
                            <div v-if="item.i_on_line == 0">
                                <img src="@/assets/off-line.png" alt="" />
                            </div>
                            <div v-else-if="item.i_fault_status == 2">
                                <img src="@/assets/Trapped.png" alt="" />
                            </div>
                            <div v-else-if="item.i_fault_status == 1">
                                <img src="@/assets/sos.png" alt="" />
                            </div>
                            <div v-else-if="item.i_mode_status != 0">
                                <img src="@/assets/overhaul.png" alt="" />
                            </div>
                            <div v-else>
                                <img src="@/assets/normal.png" alt="" />
                            </div>
                            <div style="font-size: 14px">{{ item.v_elevator_name }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="item.key == 'map'" class="map">
                <Baidumap ref="Baidumap" />
            </div>
        </div>
    </div>
</template>
<script>
import * as ElevatorApi from '@/api/elevator/index'
import * as HomeApi from '@/api/home/index'

import Baidumap from '@/pages/map/map.vue'
export default {
    data() {
        return {
            stateActive: 'map',
            modeList: [], //模式列表 
            elevatorList: [],

        }
    },
    components: {
        Baidumap
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        deviceList() {
            if (this.stateActive.indexOf('device') > -1) {
                let elevators = JSON.parse(JSON.stringify(this.elevatorList))
                return elevators.filter((elevator) => {
                    return elevator.villageId == this.stateActive.split('_')[1]
                })
            } else {
                return []
            }

        }
    },
    async mounted() {
        const villagesRes = await HomeApi.searchVillageSelectList(this.buildParams({}))
        if (villagesRes && villagesRes.info && villagesRes.info.length) {
            let deviceModeList = villagesRes.info.map((village) => {
                return {
                    label: villagesRes.info.length > 1?village.v_village_name:'设备模式',
                    key: `device_${village.v_village_id}`,
                }
            })
            deviceModeList.push({
                label: '地图模式',
                key: 'map',
            })
            this.modeList = deviceModeList
        } else {
            this.modeList = this.defaultModeList()
        }
        this.getElevatorsStatus()
    },
    methods: {
        refresh() {
            return new Promise(async resolve => {
                try {
                    if (this.stateActive == 'map') {
                        if (this.$refs.Baidumap) {
                            this.$refs.Baidumap[0].updateElevatorStatus()
                        }
                    } else {
                        await this.getElevatorsStatus()
                    }
                    resolve()
                } finally {}
            })

        },
        // 设备模式
        async getElevatorsStatus() {
            return new Promise(async resolve => {
                try {
                    const res = await ElevatorApi.getElevatorsStatus({
                        projectId: this.buildParams({}).vProjectId
                    })
                    this.elevatorList = res.info
                    resolve()
                } finally {}
            })
        },
        onChangeState(item) {
            this.stateActive = item.key
        },
        onGoMonitor(item) {
            this.$router.push({
                path: '/monitor/single',
                query: {
                    villageId: item.villageId,
                    registerNum: item.register_number,
                    elevatorId: item.v_elevator_id,
                    elevatorCode: item.v_elevator_code
                }
            })
        },

        defaultModeList() {
            return [{
                label: '设备模式',
                key: 'device'
            }, {
                label: '地图模式',
                key: 'map'
            }]
        },
        buildParams(params) {
            params.vProjectId = this.user.user.v_project_id
            return params
        }
    },
    watch: {
        stateActive: {
            handler: function(val) {
                if (val && val.indexOf('device') > -1) {
                    this.getElevatorsStatus()
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/common/styles/handle.scss';

.vallage-list {
    flex: 9;
    // height: 100%;
    @include background_color(homeVillageListBgColor);
    border: 1px solid #ddd;
    @include border_color(panelBorderColor);
    overflow: hidden;

    .nav {
        height: 50px;
        @include background_color(homeNavBgColor);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .state {
            margin-top: 5px;
            margin-left: 5px;
            display: flex;
            height: 45px;
            line-height: 45px;

            .type {
                color: #fff;
                background-image: none;
                padding: 0 20px;
                cursor: pointer;
                border-radius: 5px 5px 0 0;
            }

            .active {
                background-color: #fff;
                color: #fff;
                // background-image: linear-gradient(to right, #00ffff, #00aff8);
                background-color: rgb(36, 157, 216);
                background: radial-gradient(ellipse, #000, #1668ac);
            }
        }
    }

    .vallage-content {
        height: calc(100% - 50px);
        @include background_color(homeVillageContentBgColor);
        @include font_color(homeVillageContentTextColor);
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-content: flex-start;
        flex-wrap: wrap;
        gap: 10px;
        overflow: auto;

        .item {
            width: calc((100% - 30px) / 4);
            //height: 120px;
            border: 1px solid #2d8cf0;
            // background-color: rgb(245, 243, 243);
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 15px;

            .item-title {
                display: flex;
                justify-content: space-between;
                align-items: top;

                p {
                    width: 6px;
                    height: 6px;
                    background-color: #aaa;
                    border-radius: 6px;
                }

                .vallage-name {
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    font-size: 20px;
                    text-shadow: 5px 2px 6px #aaa;
                    // background-color: rgb(233, 225, 225);
                    margin: 0 50px 0 10px;
                    font-weight: 700;
                }

                .ele-num {
                    margin-right: 20px;
                    color: #007be5;
                    font-weight: 700;
                    font-size: 20px;
                }
            }

            .ele-state {
                display: flex;
                justify-content: space-between;
                gap: 10px;

                .state {
                    flex: 1;
                    text-align: center;
                }

                .state-num {
                    font-size: 16px;
                    font-weight: 700;
                }

                .state-type {
                    margin-top: 4px;
                    padding: 2px;
                    @include font_color(homeVillageContentTextColor);

                    color: #000;
                    font-size: 13px;
                    font-weight: 700;
                    // background-color: #edeef6;
                }
            }
        }

        .item:hover {
            border: 1px solid #2d8cf0;
            // background-color: #2d8cf0;
        }
    }

    .elevator-content {
        height: calc(100% - 50px);
        padding: 10px;
        overflow: auto;
        // background-color: pink;

        .ele-state {
            height: 30px;
            line-height: 30px;
            margin-bottom: 10px;
            display: flex;

            .item {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                margin-right: 20px;

                p {
                    width: 10px;
                    height: 10px;
                    border-radius: 10px;
                    margin-right: 4px;
                }

                .label {
                    color: #fff;
                }
            }
        }

        .elevator-list {
            .item-box {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(auto-fill, 86px);
                /* 自动填充列，每列宽度200px */
                grid-auto-rows: auto;
                grid-gap: 15px;

                box-sizing: border-box;
                border-radius: 5px;
                border: 2px solid #2d8cf0;
                position: relative;

                .title {
                    margin-right: 50px;
                    padding: 0 6px;
                    writing-mode: vertical-rl;
                    text-orientation: upright;
                    font-size: 18px;
                    margin: auto 0;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    // color: #0cebb3;
                    @include font_color(homeElevatorItemTextColor);
                    text-shadow: 5px 2px 6px #515a6e;
                    font-weight: 700;
                }

                .item {
                    border-radius: 5px;
                    background-color: pink;
                    @include background_color(homeElevatorItemBgColor);
                    @include font_color(homeElevatorItemTextColor);
                    // display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    align-items: center;
                    font-size: 12px;

                    img {
                        width: 60px;
                        height: 60px;
                        margin: 6px 0;
                    }
                }
            }
        }
    }

    .map {
        height: calc(100% - 50px);
        padding: 10px;
        overflow: auto;
        @include background_color(homeMapBgColor);
        position: relative;
    }

    .drow {
        width: 300px;
        height: 96%;
        //   background: pink;
        background-color: #0d1d4d;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .vallage-content::after {
        content: '';
        flex: auto;
    }

    .vallage-content::-webkit-scrollbar {
        display: none;
    }

    .elevator-list::after {
        content: '';
        flex: auto;
    }

    .elevator-content::-webkit-scrollbar {
        display: none;
    }
}

.grid-container {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 82px);
    grid-auto-rows: 82px;
    /* 每行高度200px */
    grid-gap: 15px;
    /* 设置间距为15 */
}

.grid-item {
    width: 100%;
    /* 宽度占满网格列 */
    height: 100%;
    /* 高度占满网格行 */
}
</style>