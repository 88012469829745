import http from '@/common/utils/http'

/** 获取电梯详情 */
export function getElevatorInfo(data) {
    return http({
        url: `wuye/getElevatorInfo`,
        method: 'post',
        data
    })
}

/** 获取电梯详情 */
export function getElevatorInfoById(id) {
    return http({
        url: `elevator/getElevatorInfo/${id}`,
        method: 'get',
    })
}

/** 获取故障与困人记录 */
export function getElevatorHlsInfo(data) {
    return http({
        url: `RuiJin/getElevatorHlsInfo`,
        method: 'post',
        data
    })
}

export function getElevatorHeatMapStatistics(data) {
    return http({
        url: `elevator/elevatorHeatMapNew`,
        method: 'post',
        data
    })
}

export function getElevatorHeatMapNewStatistics(data) {
    return http({
        url: `wuye/elevatorHeatMapNew`,
        method: 'post',
        data
    })
}

export function getElevatorScreenInfo(data) {
    debugger
    return http({
        url: `elevatorScreen/elevatorInfo/${data.elevatorCode}`,
        method: 'get',
    })
}

export function searchElevatorNotPage(data) {
    return http({
        url: `elevator/searchElevatorNotPage`,
        method: 'post',
        data
    })
}


/** 获取电梯列表 */
// export function getElevatorsStatus(data) {
//   return http({
//     url: `commonBigScreen/elevator/getElevatorsStatus`,
//     method: 'post',
//     data
//   })
// }

/** 获取电梯列表 */
export function getElevatorsStatus(data) {
  return http({
    url: `commonBigScreen/elevator/getElevatorsStatusWithMxData`,
    method: 'post',
    data
  })
}

/** 获取电梯数量  */
export function countElevator() {
  return http({
    url: `elevator/countElevator`,
    method: 'get'
  })
}