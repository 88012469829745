<template>
    <bm-overlay ref="customOverlay" class="sample" pane="labelPane" @draw="draw">
        <div @click="onTap" :title="item.v_elevator_name">
            <div class="sample normal" v-if="item.status == 1"></div>
            <div class="sample repair" v-if="item.status == 3"></div>
            <div class="sample offline" v-if="item.status == 4" :class="
          item.fault &&
          item.showFault &&
          !closeFaultIds.includes(item.v_fault_id)
            ? 'createAnimation'
            : ''
        "></div>
            <div class="sample trap" v-if="item.status == 5" :class="
          item.fault &&
          item.showFault &&
          !closeFaultIds.includes(item.v_fault_id)
            ? 'createAnimation'
            : ''
        "></div>
            <div class="sample fault" v-if="item.status == 2" :class="
          item.fault &&
          item.showFault &&
          !closeFaultIds.includes(item.v_fault_id)
            ? 'createAnimation'
            : ''
        "></div>
            <div class="placeholder" v-if="
          item.fault &&
            item.showFault &&
            !closeFaultIds.includes(item.v_fault_id)
        ">
                <div class="popover-content box-shadow">
                    <div class="popover-header">
                        <img style="width: 20px; height: 20px; position: absolute; right: 0; top: 50%; transform: translate(0,-50%);" src="@/assets/ic_pop_close.png" @click.stop="onClose" />
                        <div>故障信息</div>
                    </div>
                    <div class="fault-tips-text">
                        <div>小区名称：</div>
                        <div>{{ item.fault.villageName }}</div>
                    </div>
                    <div class="fault-tips-text">
                        <div>电梯名称：</div>
                        <div>{{ item.fault.v_elevator_name }}</div>
                    </div>
                    <div class="fault-tips-text">
                        <div>故障名称：</div>
                        <div>{{ item.fault.v_fault_name }}</div>
                    </div>
                </div>
            </div>
        </div>
    </bm-overlay>
</template>
<script>
export default {
    props: ['active', 'item', 'position'],
    data() {
        return {
            tooltipDisabled: true,
            alertVisiable: true,
            tooltipManual: false,
            toolTipsShow: true
        }
    },
    watch: {
        position: {
            handler() {
                this.$refs.customOverlay.reload()
            },
            deep: true
        },
        'item.fault': {
            handler() {},
            deep: true
        }
    },
    computed: {
        closeFaultIds() {
            return this.$store.state.event.closeFaultIds
        }
    },
    methods: {
        reload() {
            this.$refs.customOverlay.reload()
        },
        draw({ el, BMap, map }) {
            const { lng, lat } = this.position
            const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat))
            el.style.left = pixel.x - 60 + 'px'
            el.style.top = pixel.y - 20 + 'px'
        },
        handleClick(village) {
            this.$emit('openMapDrawer', village)
        },
        onTap() {
            this.$router.push({
                path: '/monitor/single',
                query: {
                    villageId: this.item.villageId,
                    registerNum: this.item.register_number,
                    elevatorId: this.item.v_elevator_id,
                    elevatorCode: this.item.v_elevator_code
                }
            })
        },
        onClose() {
            //移除困人提示
            this.$store.commit('setCloseFaultIds', this.item.fault.v_fault_id)
            delete this.item.fault
        }
    }
}
</script>
<style lang="scss" scoped>
.sample {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    text-align: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: white;
    position: absolute;
    font-size: 24px;
    box-shadow: 0 0 10px rgb(56, 56, 56);
    cursor: pointer;
}

.trap {
    z-index: 904;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.3), red);
    animation: huxi 300ms ease-out infinite alternate;
}

.normal {
    z-index: 900;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.3), rgb(34, 197, 94));
}

.fault {
    z-index: 903;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.3), rgb(255, 165, 0));
}

.repair {
    z-index: 902;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.3), rgb(0, 255, 255));
}

.offline {
    z-index: 901;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.3), rgb(255, 102, 153));
}

p {
    /* background-color: yellow; */
    /* width: 100%;
  height: 100%; */
}

.placeholder {
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    text-align: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: white;
    position: absolute;
    font-size: 24px;
    z-index: 905;
}


.sample::-webkit-scrollbar {
    display: none;
}

.sample.active {
    background: rgba(0, 0, 0, 0.75);
    color: #fff;
}

.line {
    display: inline-block;
    background: red;
}

.behavior-logo {
    position: relative;
    font-size: 26px;
}

.fault-tips-text {
    display: flex;
    justify-content: space-between;
    color: #333;
    border-bottom: 1px solid #f1f1f1;

    div:first-of-type {
        min-width: 80px;
    }

    div:last-of-type {
        text-align: right;
    }

    div {
        padding: 10px 10px;
        font-size: 12px;
        line-height: 20px;
    }
}

.popover-content {
    border-radius: 5px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    bottom: 50px;
    width: 200px;
    background: #fff;
    font-size: 12px;
    padding-bottom: 15px;
    animation-duration: 5s;
    /* don't forget to set a duration! */
}

.popover-header {
    color: 14px;
    color: #000;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popover-content::before {
    content: '';
    position: absolute;
    bottom: -10px;
    /* 箭头距离Popover底部的高度 */
    left: 50%;
    /* 水平居中 */
    transform: translateX(-50%) rotateY(180deg);
    border-width: 6px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
    /* 箭头三角形的颜色 */
}

@keyframes huxi {
    0% {
        opacity: 0.2;
        transform: scale(1);
        box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1);
    }

    100% {
        opacity: 1;
        transform: scale(1.1);
        box-shadow: 0 1px 30px red;
    }
}

.box-shadow {
    border-radius: 15rpx;
    box-shadow: 0rpx 0rpx 50rpx 0rpx rgba(0, 0, 0, 0.07);
}
</style>