<template>
    <div class="home" id="testV">
        <div class="home-left">
            <div v-if="rescueInfo" class="sos">
                <Icon style="font-size: 26px; color: red" type="ios-flash" />
                <div class="text">紧急救援</div>
                <div class="scool">
                    <div class="blink" @click="
              () => {
                $router.push({
                  path: '/monitor/single',
                  query: {
                    eventid: rescueInfo.event_id,
                    elevatorCode: rescueInfo.v_elevator_code
                  }
                })
              }
            ">
                        {{ rescueInfo.v_elevator_code }}
                    </div>
                </div>
            </div>
            <div class="table">
                <border-box id="box4">
                    <div class="fault">
                        <div class="tips">
                            <div class="round">
                                <div class="text">
                                    <div>故障</div>
                                    <div>提示</div>
                                </div>
                            </div>
                        </div>
                        <div class="list">
                            <el-table class="custom-table" id="singleTable" ref="tableRef" :data="elevatorHlsInfoFault" highlight-current-row style="width: 100%; height: 100%">
                                <el-table-column label="上报日期" :width="width0" align="center">
                                    <template slot-scope="scope">
                                        {{ formatDateVal(scope.row.dt_report_time) }}
                                    </template>
                                </el-table-column>
                                <el-table-column property="v_fault_name" :width="width1" :show-overflow-tooltip="true" label="故障类型" align="center">
                                </el-table-column>
                                <el-table-column property="v_elevator_name" :width="width2" :show-overflow-tooltip="true" label="电梯名称" align="center">
                                </el-table-column>
                                <el-table-column label="操作" align="center">
                                    <template slot-scope="scope">
                                        <el-button @click="onViewFault(scope.row.v_fault_id)" type="primary" size="mini">
                                            回看
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </border-box>
                <border-box id="box4">
                    <div class="fault">
                        <div class="tips">
                            <div class="round" style="background: orange">
                                <div class="text">
                                    <div>不文明</div>
                                    <div>行为</div>
                                </div>
                            </div>
                        </div>
                        <div class="list">
                            <el-table :data="elevatorHlsInfoUncivilized" highlight-current-row style="width: 100%; height: 100%">
                                <el-table-column label="上报日期" :width="width0" align="center">
                                    <template slot-scope="scope">
                                        {{ formatDateVal(scope.row.dt_report_time) }}
                                    </template>
                                </el-table-column>
                                <el-table-column property="v_fault_name" :width="width1" label="不文明行为类型" :show-overflow-tooltip="true" align="center">
                                </el-table-column>
                                <el-table-column property="v_elevator_name" :width="width2" label="电梯名称" align="center" :show-overflow-tooltip="true">
                                </el-table-column>
                                <el-table-column label="操作" align="center">
                                    <template slot-scope="scope">
                                        <el-button @click.native.prevent="onViewFault(scope.row.v_fault_id)" type="primary" size="mini">
                                            回看
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </border-box>
            </div>
            <MXModeWidget ref="modeWidgetRef"/>
        </div>
        <div class="home-right">
            <border-box id="box1" style="min-height: 90px!important;">
                <div class="num">
                    <div class="flex ele-num">
                        <div>
                            <div class="nor-text" style="font-size: 14px">电梯总数</div>
                            <div class="nor-text" style="font-size: 30px; font-weight: 700; text-align: center;">
                                {{ elevatorCount.total }}
                            </div>
                        </div>
                    </div>
                    <div class="flex ele-num">
                        <div>
                            <div class="nor-text" style="font-size: 14px">物联网电梯</div>
                            <div class="highlight-text" style="font-size: 30px; font-weight: 700; text-align: center;">
                                {{ elevatorCount.total }}
                            </div>
                        </div>
                    </div>
                </div>
            </border-box>
            <border-box id="box2">
                <tag-box class="pull-left">电梯状态</tag-box>
                <div class="pie pull-right" style="display: flex; gap: 12px">
                    <div style="flex: 1" class="panel-body one">
                        <div class="pie-split-wrapper">
                            <div class="pieTop">
                                <div class="echart-box" id="E_zkcl2"></div>
                            </div>
                        </div>
                        <div class="pie-split-wrapper">
                            <div class="content">
                                <div class="item" v-for="(item, index) in chartData2" :key="index">
                                    <div class="left">
                                        <div :style="{
                        width: '16px',
                        height: '16px',
                        background: item.color,
                        'border-radius': '50%',
                        'margin-right': '10px'
                      }"></div>
                                        <div>{{ item.name }}</div>
                                    </div>
                                    <div class="right">{{ item.number }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </border-box>
            <border-box id="box3">
                <div style="display:flex;justify-content: space-between;">
                    <tag-box class="pull-left">运行指标</tag-box>
                    <div class="search">
                        <div class="time-select">
                            <p @click="onChangeTime('dd')" class="time week" :class="timeType == 'dd' ? 'active' : ''">
                                日
                            </p>
                            <p @click="onChangeTime('11')" class="time mouth" :class="timeType == '11' ? 'active' : ''">
                                月
                            </p>
                            <p @click="onChangeTime('22')" class="time year" :class="timeType == '22' ? 'active' : ''">
                                年
                            </p>
                        </div>
                    </div>
                </div>
                <div class="main-info pull-right">
                    <div class="contemt" v-if="!dataLoading">
                        <div v-for="(item, index) in intellData" :key="index">
                            <p>{{ index + 1 }}</p>
                            <span>{{ item.label }}：{{ item.number }}</span>
                        </div>
                    </div>
                    <div class="contemt" style="text-align: center;" v-else>
                        数据加载中......
                    </div>
                </div>
            </border-box>
        </div>
        <Modal footer-hide :mask="true" :mask-closable="false" :scrollable="true" v-model="modaDetail" width="550" class="model">
            <div style="background: #fff">
                <Row :gutter="10">
                    <Tabs size="small">
                        <TabPane label="视频" class="TimeDiv">
                            <div style="height:100%; display:flex; align-items: center;" v-if="videoUrl != '' && videoUrl != null">
                                <video :src="videoUrl" controls ref="video">
                                    您的浏览器不支持video元素。
                                </video>
                            </div>
                            <div v-else style="height:100%; display: flex; justify-content:center;align-items: center;">
                                <font>暂无视频</font>
                            </div>
                        </TabPane>
                        <TabPane label="图片">
                            <div class="fault-imgs-box" v-if="faultImageList.length > 0">
                                <div class="fault-img" :key="index" dis-hover v-for="(item, index) in faultImageList">
                                    <img :src="item" style="max-width:100%" />
                                </div>
                            </div>
                            <div v-else style="height:100%; display: flex; justify-content:center;align-items: center;">
                                <font>暂无图片</font>
                            </div>
                        </TabPane>
                    </Tabs>
                </Row>
            </div>
        </Modal>
    </div>
</template>
<script>
import MXModeWidget from '@/pages/components/mx-biz-components/mx-mode-widget'
import BorderBox from '../../components/BorderBox'
import TagBox from '../../components/TagBox'

import {
    getElevatorHlsInfo,
    searchFaultList,
    getFaultDetail,
    villageCount,
    getElevatorsStatus,
    villagesCountRate,
    personShuttingLately,
    searchUncivilizedltList,
    searchMaiXinFaultList
} from '@/api/home/index.js'

import * as ElevatorApi from '@/api/elevator/index'
import * as HomeApi from '@/api/home/index'

export default {
    data() {
        return {
            tableData: [],

            chartData2: [],
            elevatorList: [],

            elevatorHlsInfoFault: [],
            elevatorHlsInfoUncivilized: [],
            input2: '',
            stateType: 1,
            timeType: 'dd',
            select: 1,
            input: '',
            center: { lng: 0, lat: 0 },
            zoom: 3,
            elevatorCount: {
                total: '--'
            },
            classificationParams: {
                register_number: '',
                villageId: '',
                timeFlag: '',
                faultType: '',
                backType: 1,
                vElevatorCode: '',
                videoUrl: ''
                // vProjectId: '8089373891892543488',
            },
            intelligentData: {
                register_number: '',
                villageId: '',
                timeFlag: 'dd',
                faultType: ''
                // vProjectId: '8089373891892543488',
            },
            listQuery: {
                iUncivilizedBehaviorFlag: 1,
                villageId: '',
                buildingId: '',
                vElevatorCode: '',
                dtReportTime: this.formatDate(-90) + '',
                dtEndTime: this.formatDate(0) + ' 23:59:59',
                iFaultType: '',
                overdue: '',
                iStatus: '',
                pageIndex: 1,
                pageSize: 20
                // vProjectId: '8089373891892543488',
            },
            faultListQuery: {
                iUncivilizedBehaviorFlag: 0,
                villageId: '',
                buildingId: '',
                vElevatorCode: '',
                dtReportTime: this.formatDate(-90) + '',
                dtEndTime: this.formatDate(0) + ' 23:59:59',
                overdue: '',
                pageIndex: 1,
                pageSize: 20
                // vProjectId: '8089373891892543488',
            },
            villageCountData: {
                overdue: '',
                pageIndex: '',
                pageSize: '',
                eType: '',
                timeFlag: 'dd',
                iUncivilizedBehaviorFlag: 0,
                isRepair: 0,
                // vProjectId: '8089373891892543488',
            },
            videoUrl: '',
            modaDetail: false,
            rescueInfo: null,
            HistoryRecordData: {
                vElevatorCode: '',
                timeFlag: '11'
            },
            SupervisionData: {
                iModeStatus: 2,
                vProjectId: ''
            },
            Supervision: '',
            intellData: [{
                    label: '困人',
                    number: '0',
                    type: 0
                },
                {
                    label: '故障',
                    number: '0',
                    type: 1
                },
                {
                    label: '电动车乘梯',
                    number: '0',
                    type: 2
                },
                {
                    label: '关门时受阻挡',
                    number: '0',
                    type: 3
                }
            ],
            villageName: '',
            elevatorData: { villageId: '', vProjectId: '' },
            faultImageList: [],

            option: {
                series: [{
                    type: 'pie',
                    radius: ['20%', '70%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    roseType: 'radius',
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            fontSize: 40,
                            fontWeight: 'bold'
                        }
                    },

                    data: [
                        { value: 2, name: 'Search Engine' },
                        { value: 0, name: 'Direct' },
                        { value: 0, name: 'Email' }
                    ]
                }]
            },
            option2: {
                series: [{
                    type: 'pie',
                    radius: ['20%', '70%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    roseType: 'radius',
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            fontSize: 40,
                            fontWeight: 'bold'
                        }
                    },

                    data: []
                }]
            },
            zoom: 1,
            eleQuantity: {
                register_number: '',
                villageId: '',
                timeFlag: 'dd',
                faultType: '',
                vProjectId: '8333958929244291072'
            },

            test: 'test-classs',

            width0: '',
            width1: '',
            width2: '',
            width3: '',
            dataLoading: false
        }
    },
    created() {},
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    components: {
        MXModeWidget,
        BorderBox,
        TagBox
    },
    created() {},
    mounted() {
        this.setupELTableObserver()
        this.refresh()
        this.getRunStatiscs()
    },
    destroyed() {},
    methods: {
        async refresh() {
            await this.searchHomeFaultList()
            await this.searchHomeUncivilizedFaultList()
            await this.$refs.modeWidgetRef.refresh()
            await this.countElevator()
            await this.Emergencyrescue()
            this.timer.add({
                ref: this,
                func: 'refresh',
                timeout: 10
            })
        },
        setupELTableObserver() {
            let myChart = this.$echarts.init(document.getElementById('E_zkcl2'))
            let elementResizeDetectorMaker = require('element-resize-detector')
            let erd = elementResizeDetectorMaker()
            let that = this
            erd.listenTo(document.getElementById('testV'), function(element) {
                const containerWidth = element.offsetWidth
                let ratio = containerWidth / 1720

                that.width1 = 140 * ratio // 第一列占父视图宽度的20%
                that.width2 = 130 * ratio // 第二列占父视图宽度的30%
                // that.width3 = 140 * ratio; // 第三列占父视图宽度的50%
                that.width0 = 180 * ratio // 第三列占父视图宽度的50%
            })
        },
        //故障详情
        getFaultDetail(faultId) {
            getFaultDetail(faultId).then(res => {
                if (res.code === '0000') {
                    console.log(res)
                    this.videoUrl = res.info.videoUrl
                    this.faultImageList = res.info.imageList
                }
            })
        },

        // 紧急救援
        Emergencyrescue() {
            personShuttingLately(this.buildParams(this.classificationParams)).then(
                res => {
                    this.rescueInfo = res.info
                }
            )
        },
        // 故障提示
        async searchHomeFaultList() {
            return new Promise(async resolve => {
                try {
                    const res = await searchFaultList(
                        this.buildParams(this.faultListQuery)
                    )
                    this.elevatorHlsInfoFault = res.info.list
                    this.elevatorHlsInfoFault.forEach(item => {
                        item.dt_report_time = item.dt_report_time
                    })
                    resolve()
                } finally {}
            })
        },
        // 不文明行为
        searchHomeUncivilizedFaultList() {
            return new Promise(async resolve => {
                try {
                    const res = await searchUncivilizedltList(
                        this.buildParams(this.listQuery)
                    )
                    this.elevatorHlsInfoUncivilized = res.info.list
                    this.elevatorHlsInfoUncivilized.forEach(item => {
                        item.dt_report_time = item.dt_report_time
                    })
                    resolve()
                } finally {}
            })
        },
        // 设备模式
        async getElevatorsStatus() {
            return new Promise(async resolve => {
                try {
                    const res = await ElevatorApi.getElevatorsStatus({
                        projectId: this.buildParams({}).vProjectId
                    })
                    this.elevatorList = res.info
                    resolve()
                } finally {}
            })
        },

        // 电梯数量
        async countElevator() {
            return new Promise(async resolve => {
                try {
                    const res = await ElevatorApi.countElevator({})
                    res.info.trappedPeople =
                        res.info.trappedPeople == undefined ? 0 : res.info.trappedPeople
                    this.updateChartByCountInfo(res.info)
                    this.elevatorCount.total = res.info.totalQuantity
                    resolve()
                } finally {}
            })
        },
        updateChartByCountInfo(info) {
            const data = [{
                    name: '离线',
                    number: info.offlineQuantity,
                    color: this.statusColorMap().offline
                },
                {
                    name: '困人',
                    number: info.trappedPeopleQuantity,
                    color: this.statusColorMap().trap
                },
                {
                    name: '检修',
                    number: info.overhaulQuantity,
                    color: this.statusColorMap().repair
                },
                {
                    name: '故障',
                    number: info.faultQuantity,
                    color: this.statusColorMap().fault
                },
                {
                    name: '正常',
                    number: info.normalQuantity,
                    color: this.statusColorMap().normal
                }
            ]
            this.chartData2 = data
            this.option2.series[0].data = data.map(function(item, index) {
                return {
                    name: item.name,
                    value: item.number,
                    itemStyle: {
                        color: item.color
                    }
                }
            })
            let myChart2 = this.$echarts.init(document.getElementById('E_zkcl2'))
            myChart2.setOption(this.option2)
        },
        // 运行指标
        async getRunStatiscs() {
            this.dataLoading = true
            try {
                const res = await HomeApi.villagesCountRate(
                    this.buildParams(this.villageCountData)
                )
                this.intellData = this.parseRunningStatics(res.info)
                this.dataLoading = false
            } finally {
                this.dataLoading = false
            }
        },
        parseRunningStatics(data) {
            let array = this.intellData
            array.find(item => {
                return item.type == 0 // 困人 peopleTrappedCount
            }).number = data.peopleTrappedCount.count
            array.find(item => {
                return item.type == 1 //故障 faultOrderByConfirmOrCompleted
            }).number = data.faultOrderByConfirmOrCompleted.count
            array.find(item => {
                return item.type == 2 //电动车乘梯 electronBikeCount
            }).number = data.electronBikeCount ? data.electronBikeCount.count : 0
            array.find(item => {
                return item.type == 3 //关门阻挡 blockDoorCount
            }).number = data.blockDoorCount ? data.blockDoorCount.count : 0

            return array
        },
        perFormat(percentage) {
            return percentage < 0.1 ? `${percentage}‰` : `${percentage}%`
        },
        goMonitor(item) {
            this.$router.push({
                path: '/monitor/single',
                query: {
                    villageId: item.villageId,
                    registerNum: item.register_number,
                    elevatorId: item.v_elevator_id,
                    elevatorCode: item.v_elevator_code
                }
            })
        },
        onViewFault(faultId) {
            this.modaDetail = true
            this.getFaultDetail(faultId)
        },
        onChangeState(state) {
            this.stateType = state
        },
        onChangeTime(state) {
            if (this.dataLoading) return
            this.timeType = state
            this.villageCountData.timeFlag = state
            this.getRunStatiscs()
        },
        getColumnWidth(index) {
            const tableRef = this.$refs.tableRef.$el
            const tableWidth = tableRef.clientWidth
            const columnPercentage = [20, 30, 50] // 设置每列的百分比宽度
            return `${(tableWidth * columnPercentage[index]) / 100}px`
        },
        statusColorMap() {
            return {
                offline: '#f69',
                trap: 'red',
                repair: '#00FFFF',
                fault: 'orange',
                normal: '#22c55e'
            }
        },
        buildParams(params) {
            params.vProjectId = this.user.user.v_project_id
            return params
        }
    },
    watch: {
        modaDetail: function(newObj) {
            if (!newObj) {
                this.videoUrl = ''
                this.faultImageList = []
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/common/styles/handle.scss';
@import './home.css';

.pie {
    .pie-split-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50%;
    }

    .pieTop {
        display: flex;
        justify-content: center;
        // background-color: pink;
    }

    .content {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        padding-left: 10px;
        gap: 10px;

        .item {
            width: calc(50% - 5px);
            height: 35px;
            display: flex;
            padding-right: 10px;
            font-size: 12px;
            justify-content: space-between;
            align-items: center;
            background-color: #0c245f;
            border: 1px solid #034e90;
            margin-bottom: 2px;

            .left {
                display: flex;
                height: 100%;
                align-items: center;
                color: #00deff;
                padding: 0 10px;
                background-color: #0b2a6f;
            }
        }
    }
}

::v-deep input[type='text'],
input[type='number'] {
    border-radius: 8px;
    height: 38px;
    margin-top: 4px;
}

::v-deep .el-input {
    width: 150px;
    margin-right: 6px;
}

::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
    border: none;
}

.el-table ::v-deep th.el-table__cell>.cell {
    @include font_color('tableHeaderTextColor');
}

.el-table {
    ::v-deep .el-table__cell {
        padding: 8px 0;
        @include font_color('tableCellTextColor');
        @include background_color('tableCellBgColor');
    }
}

.el-table {
    ::v-deep .el-table__empty-block {
        @include background_color('tableEmptyBgColor');
    }
}

.el-table {
    ::v-deep th.el-table__cell.is-leaf {
        @include border_color(seperatorColor);
    }
}

.el-progress-circle {
    width: 40px;
    height: 40px;
    color: red;
}

.el-progress--circle ::v-deep .el-progress__text {
    @include font_color(homeStatusProgressTextColor);
}

::v-deep .el-table__body-wrapper {
    height: calc(100% - 40px);
    overflow-y: auto !important;
}

::v-deep.el-button--primary {
    border-radius: 0;
    // background-image: linear-gradient(to right, #00ffff, #00aff8);
    background-color: rgb(36, 157, 216);
    background: radial-gradient(ellipse, #000, #1668ac);
    border: none;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 0 !important;
}

::v-deep .el-table__body-wrapper {
    -ms-overflow-style: none;
}

::v-deep .el-table__body-wrapper {
    overflow: -moz-scrollbars-none;
}

::v-deep .el-table::before {
    height: 0px !important;
}

::v-deep .el-table,
.el-table__expanded-cell {
    background: none !important;
}

::v-deep .el-table tr {
    background: none;
}

::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
    background: none;
}

.search {
    display: flex;

    .time-select {
        display: flex;
        justify-content: space-between;
        align-items: center;
        //   background-image: linear-gradient(to right, #00ffff, #00aff8);
        //   background-color: #2d8cf0;
        border: 1px solid #fff;
        border-radius: 6px;
        color: #fff;

        .time {
            padding: 8px 16px;
            cursor: pointer;
        }

        .week {
            border-radius: 6px 0 0 6px;
        }

        .year {
            border-radius: 0 6px 6px 0;
        }

        .mouth {
            border: 1px solid #fff;
            border-top: none;
            border-bottom: none;
        }

        .active {
            // background-color: #fff;
            color: #fff;
            // background-image: linear-gradient(to right, #00ffff, #00aff8);
            background-color: rgb(36, 157, 216);
            background: radial-gradient(ellipse, #000, #1668ac);
        }
    }
}

.red {
    color: #d1217f;
}

.blue {
    color: #007be5;
}

.pink {
    color: #ef4444;
}

.weight {
    font-weight: 700;
    font-size: 20px;
}

.orange {
    color: #f59e0b;
}

.bg-white {
    background-color: #fff;
}

.font-s {
    margin-top: 2px;
    margin-right: 5px;
    // color: #707070;
}

.state-flex {
    display: flex;
    align-items: center;
    // justify-content: center;
    padding-left: 10px;
}

.flex {
    flex: 1;
}

.home {
    height: 100%;
    background-color: #fff;
    display: flex;
    gap: 10px;
    padding: 10px;
    @include background_color('homeBgColor');
}

.home-left {
    flex: 12;
    display: flex;
    gap: 10px;
    flex-direction: column;

    .sos {
        display: flex;
        align-items: center;
        height: 50px;
        background-color: #fff;
        padding-left: 18px;
        border: 1px solid #ddd;

        .text {
            color: red;
            font-weight: 700;
            font-size: 16px;
            letter-spacing: 1px;
            margin-right: 10px;
        }

        .scool {
            flex: 1;
            overflow: hidden;
        }

        ul {
            display: flex;
            flex-direction: row;

            li {
                cursor: pointer;
                background-color: red;
                color: #fff;
                font-weight: 700;
                padding: 0 10px;
                margin-right: 10px;
            }
        }
    }

    .table {
        flex: 3;
        // height: 300px;
        display: flex;
        gap: 10px;
        overflow-y: auto;

        .fault {
            flex: 1;
            display: flex;
            height: 100%;
            @include background_color('faultBgColor');
            overflow: auto;
            //   border: 1px solid #ddd;
            @include border_color(panelBorderColor);

            .tips {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .round {
                    width: 68px;
                    height: 68px;
                    font-size: 14px;
                    font-weight: 700;
                    margin: 0 auto;
                    text-align: center;
                    background-color: red;
                    border-radius: 68px;
                    color: #fff;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }

            .list {
                flex: 6;
                overflow-x: hidden;
            }
        }

        .uncivilized {
            flex: 1;
            background-color: #fff;
        }
    }

    .shadow-wrapper {
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0px;
        width: 90%;
        height: 100%;
    }
}

.hiu {
    background-color: pink;
}

.home-right {
    height: 100%;
    flex: 3;
    // background-color: red;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .num {
        margin-top: 10px;
        // padding: 10px;
        flex: 1;
        display: flex;

        @include border_color(panelBorderColor);
        gap: 10px;
        @include background_color(homeOverviewBgColor);
        background-color: #fff;

        .ele-num {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 10px;

            .nor-text {
                @include font_color(homeOverviewTextColor);
            }

            .highlight-text {
                @include font_color(homeOverviewHighlightTextColor);
            }
        }
    }

    .state {
        flex: 4;

        .title {
            height: 50px;
            line-height: 50px;
            font-weight: 700;
            font-size: 24px;
            color: #000;
            @include font_color(homeStatusTitleTextColor);
        }

        .main-state {
            //   border: 1px solid #ddd;
            @include border_color(panelBorderColor);
            // height: calc(100% - 50px);
            border-bottom: none;
        }
    }

    .info {
        flex: 5;
        display: flex;
        flex-direction: column;

        .title {
            height: 50px;
            line-height: 50px;
            font-weight: 700;
            font-size: 24px;
            @include font_color(homeInfoTitleTextColor);
        }
    }

    .main-info {
        //   border: 1px solid #ddd;
        @include border_color(panelBorderColor);
        height: calc(100% - 50px);
        @include background_color(homeInfoBgColor);
        @include font_color(homeInfoTextColor);
        display: flex;
        flex-direction: column;

        .contemt {
            flex: 1;
            padding: 10px;
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            div {
                display: flex;
                align-items: center;

                p {
                    background-color: #2d8cf0;
                    color: #fff;
                    margin-right: 10px;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    border-radius: 30px;
                    text-align: center;
                }
            }
        }

        .peo-info {
            margin-top: 10px;
            height: 40px;
            padding-left: 20px;
            background-color: #fff;

            i {
                font-size: 18px;
                margin-right: 6px;
            }
        }
    }
}



#box1 {
    height: 12%;
}

#box2 {
    height: 50%;
}

#box3 {
    height: 38%;
}

#box4 {
    width: 50%;
    height: 100%;
}

#box5 {
    height: 70%;
}

.el-row {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}

.bg-purple-dark {
    background: #fff;
}

.bg-purple {
    @include background_color(homeBgPurpleBgColor);
    @include border_color(seperatorColor);
}

.bg-purple-light {
    background: #fff;
}

.grid-content {
    min-height: 70px;
}

.row-bg {
    // padding: 10px 0 0;
    background-color: #fff;
}

.svg-icon_home_index {
    width: 2.5em;
    height: 2.5em;
    fill: currentColor;
    overflow: hidden;
}

video {
    width: 100%;
    max-height: 500px;
}

.fault-img {
    margin-bottom: 20px;
}

.fault-img:last-of-type {
    margin-bottom: 0px;
}

.fault-imgs-box {
    max-height: 500px;
    overflow-y: auto;
}


.pull-left {
    height: 45px;
    float: left;
    font-size: 16px;
}

.panel-title {
    padding-right: 16px;

    .tag-box {
        margin-top: -2px;
        margin-left: -2px;
    }
}

.clearfix {
    zoom: 1;
}

.clearfix:after {
    content: '.';
    display: block;
    clear: both;
    height: 0;
    visibility: hidden;
}

.pull-right {
    height: calc(100% - 45px);
    float: right !important;
    width: 100%;
}

.status {
    line-height: 45px;
    font-size: 24px;
}

.echart-box {
    width: 180px;
    height: 180px;
}

.offline {
    background-color: #ddd;
}

.test-class {
    background-color: red;
}

::v-deep.el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: hidden;
}
</style>